import React from 'react';

function App() {
  return [(
    <section id="main" key="main">
      <header>
        <span className="avatar"><img src={process.env.PUBLIC_URL + '/images/logo.jpg'} alt="logo fromidable" width="122px"/></span>
        <h1>Fromidable</h1>
        <p>Spécialités végétales de caractère<br />Affinées à Lille
        </p>
        <a href="https://fromidable.sumupstore.com">Boutique en ligne</a>
        <p>
        <a href="https://www.kisskissbankbank.com/fr/projects/une-part-fromidable">Notre campagne de financement participative</a>
        </p>
      </header>
      <footer>
        <ul className="icons" key="social-network">
          <li key="instagram"><a href="https://www.instagram.com/fromidable.lille/
            " className="fa-instagram">Instagram</a></li>
          <li key="facebook"><a href="https://www.facebook.com/Fromidable.lille/" className="fa-facebook">Facebook</a></li>
        </ul>
      </footer>
    </section>
  ),(
    <footer id="footer" key="footer">
      <ul className="copyright" key="footer">
        <li key="copyright">&copy; Fromidable</li>
        <li key="link"><a href="https://www.fromidable.fr">www.fromidable.fr</a></li>
      </ul>
     </footer>
  )];
}


export {App};
